
// Utils
import { masks, numberInputs, addMask, setCursor } from '@@/common/assets/js/utils/mask-utils';

/**
 * Позволяет пользователю ввести данные с помощью клавитуры.<br>
 * Можно использовать вместе с утилитами из validate-utils, для проверки корректности ввода, по заданному параметру.
 *
 * @version 1.0.1
 * @displayName VInput
 */
export default {
    name: 'VInput',

    props: {
        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => ['medium'].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'primary',
            validator: val => ['primary'].includes(val),
        },

        /**
         * Тип, для передачи в атрибуты нативного инпута
         */
        type: {
            type: String,
            default: 'text',
        },

        /**
         * Текущее введёное значение
         */
        value: {
            type: String,
            default: '',
        },

        autocomplete: {
            type: Boolean,
            default: false,
        },

        /**
         * Лейбл инпута
         */
        label: {
            type: String,
            default: '',
        },

        /**
         * Сообщение пользователю, может использоваться для валидации
         */
        msg: {
            type: String,
            default: '',
        },

        /**
         * Параметр маски инпута, если необходим
         * @values phone, date, time, number, snils, inn, passport, payment, percent, year, pin, months
         */
        mask: {
            type: String,
            default: '',
        },

        /**
         * Отображение маску при вводе
         */
        premask: {
            type: Boolean,
            default: true,
        },

        /**
         * Если активно, то при эмите, данные будут переданы с учётом активной маски.
         * Т.е. если в инпуте компонента +7 (999) 00-00-00, то без этого параметра,
         * будет передано 9990000. Включено по-умолчанию, для валидации
         */
        keepMasked: {
            type: Boolean,
            default: true,
        },

        error: Boolean,

        disabled: Boolean,
    },

    data() {
        return {
            isFocused: false,
            currentMask: '',
        };
    },

    computed: {
        classList() {
            return {
                [this.$style[`_${this.color}`]]: this.size,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style._active]: this.value || this.isFocused,
                [this.$style._disabled]: this.disabled,
                [this.$style._error]: this.error,
                [this.$style._showLabel]: Boolean(this.label),
                [this.$style._success]: this.mask && !this.error && this.currentMask.length === this.inputValue.length,
            };
        },

        inputValue() {
            if (this.mask) {
                return addMask(this.value, this.currentMask);
            }
            return this.value;
        },

        currentPremask() {
            if (!this.mask) {
                return '';
            }

            if (this.inputValue.length) {
                const regex = new RegExp('^.{0,' + this.inputValue.length + '}', 'g');
                const pre = this.currentMask.replace(regex, `<span>${this.inputValue}</span>`);
                return pre.replace(/#/g, '&ensp;');
            } else {
                return this.currentMask.replace(/#/g, '&ensp;');
            }
        },

        attributes() {
            const attrs = {
                ...this.$attrs,
                type: 'text',
                disabled: this.disabled,
            };

            if (this.label) {
                attrs.ariaLabel = this.label;
            }

            if (this.mask) {
                attrs.maxlength = this.currentMask.length;
            }

            if (this.type) {
                if (this.mask) {
                    attrs.type = numberInputs.includes(this.mask) ? 'tel' : 'text';
                } else {
                    attrs.type = this.type;
                }
            }

            if (!this.autocomplete) {
                attrs.autocomplete = 'off';
            }

            return attrs;
        },
    },

    created() {
        if (this.mask) {
            try {
                this.currentMask = masks[this.mask];
                if (!this.currentMask) {
                    throw new Error(`VInput: mask-utils: mask ${this.mask} not found`);
                }
            } catch (e) {
                console.warn(e);
            }
        }
    },

    methods: {
        /**
         * Эмитит новые значения в родительский компонент.
         * Используется для валидации.
         * @returns {String} e.target.value Введёный текст
         * @public
         */
        onInput(e) {
            if (this.mask) {
                let position = e.target.selectionEnd;
                const digit = e.target.value[position - 1];

                // Заменяет 8 при вводе на +7,
                // если это первый символ
                if (this.mask === 'phone' && e.target.value.charAt(0) == 8) {
                    e.target.value = '+7';
                }

                e.target.value = addMask(e.target.value, this.currentMask);

                if (position !== 0) {
                    while (position < e.target.value.length && e.target.value.charAt(position - 1) !== digit) {
                        position++;
                    }
                }

                setCursor(e.target, position);

                if (this.mask === 'percent') {
                    if (e.target.value === '00') {
                        e.target.value = 1;
                    }
                    e.target.value = e.target.value > 100 ? '100%' : e.target.value + '%';
                } else if (this.mask === 'months') {
                    if (e.target.value > 360) {
                        e.target.value = 360;
                    }
                }
                if (e.target.value !== this.inputValue) {
                    const emitVal = this.keepMasked
                        ? e.target.value
                        : addMask(e.target.value, this.currentMask, false);
                    this.$emit('input', emitVal);
                    return;
                }
            }

            /**
             * Возвращает новое значение в родительский компонент.
             * Помогает при валидации поля.
             @param {String} value Новое значение
             */
            this.$emit('input', e.target.value);
        },

        /**
         * Метод, который обрабатывает событие focus на инпуте
         * @public
         */
        onFocus(e) {
            this.isFocused = true;

            // Automatically add '+7' characters
            if (this.mask && this.mask === 'phone' && !this.inputValue) {
                this.$nextTick(() => {
                    setCursor(e.target, this.inputValue.length);
                });
            }

            /**
             * Передаёт родителю, что компонент находится в focus.
             * В большинстве реализаций - может и не пригодится
             * @event focus
             */
            this.$emit('focus', e);
        },

        /**
         * Метод, который обрабатывает событие blur на инпуте
         * @public
         */
        onBlur(e) {
            this.isFocused = false;

            /**
             * Передаёт родителю, что компонент больше не находится в focus.
             * В большинстве реализаций - может и не пригодится
             * @event blur
             */
            this.$emit('blur', e);

            // Automatically remove '+' or '+7' character
            if (this.mask && this.mask === 'phone' && (this.inputValue === '+' || this.inputValue === '+7')) {
                this.$emit('input', '');
            }
        },
    },
};
