
/**
 * Кастомная альтернатива тега button в стандартном html<br>
 * На проектах, обычно, имеет несколько цветов, форм и состояний.<br><br>
 * Область применения: ссылка, изменения состояния, вызов методов.
 *
 * @version 1.0.1
 * @displayName VButton
 */
export default {
    name: 'VButton',

    props: {
        /**
         * Определяет тег компонента
         */
        tag: {
            type: String,
            default: 'button',
            validator: v => [
                'n-link',
                'a',
                'span',
                'div',
                'button',
                'input',
            ].includes(v),
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => ['medium', 'semi-medium', 'medium-44', 'medium-4', 'custom'].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'primary',

            validator: value => ['primary', 'transparent'].includes(value),
        },

        /**
         * Активное состояние кнопки
         */
        active: Boolean,

        /**
         * Это свойство отключает взаимодействие
         */
        disabled: Boolean,

        /**
         * если внутри только иконка
         */
        iconOnly: Boolean,

        /**
         * отключает тень в кнопке
         */
        noBoxShadow: Boolean,

        border: Boolean,

        /**
         * Для постановки hover через js
         */
        hovered: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return [{
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style._disabled]: this.disabled,
                [this.$style._active]: this.active,
                [this.$style._iconOnly]: this.iconOnly,
                [this.$style._noBoxShadow]: this.noBoxShadow,
                [this.$style._border]: this.border,
                [this.$style._hovered]: this.hovered,
            }];
        },
    },

    methods: {
        /**
         * Эмитит событие клика в родительский компонент
         * @param {Event} event mouse event
         * @public
         */
        onClick($event) {
            /**
             * Cобытие клика в родительский компонент
             * @event click
             * @param {Event} event mouse event
             */
            if (this.disabled) {
                return;
            }

            this.$emit('click', $event);
        },

        /**
         * Эмитит cобытие при наведении на элемент
         * @param {Event} event mouse event
         * @public
         */
        onMouseEnter($event) {
            /**
             * Cобытие при наведении на элемент
             * @event mouseenter
             * @param {Event} event mouse event
             */
            this.$emit('mouseenter', $event);
        },

        /**
         * Эмитит событие, когда наведение на элемент прекращено
         * @param {Event} event mouse event
         * @public
         */
        onMouseLeave($event) {
            /**
             * Cобытие, когда наведение на элемент прекращено
             * @event mouseleave
             * @param {Event} event mouse event
             */
            this.$emit('mouseleave', $event);
        },
    },
};
