// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VCircleButton_mDgyh{align-items:center;border-radius:50%;box-shadow:0 0 1px #0000;cursor:pointer;display:inline-flex;font-size:1.2rem;justify-content:center;line-height:1;outline:none;position:relative;text-align:center;transform:translateZ(0) rotate(0);transition:color .3s ease,opacity .3s ease,background-color .3s ease,border-color .3s ease;-webkit-user-select:none;user-select:none;white-space:nowrap}.VCircleButton_mDgyh._disabled_XXltS{opacity:.5;pointer-events:none}.VCircleButton_mDgyh._small_bmQRF{height:3.2rem;width:3.2rem}.VCircleButton_mDgyh._medium_qgK9g{height:4.2rem;width:4.2rem}@media(max-width:767.98px){.VCircleButton_mDgyh._medium_qgK9g{height:40px;width:40px}.VCircleButton_mDgyh._medium_qgK9g .text_1gBnr svg{height:14px;width:14px}}.VCircleButton_mDgyh._large_5TPT\\+{height:4.8rem;width:4.8rem}@media(max-width:1279.98px){.VCircleButton_mDgyh._large_5TPT\\+{height:4.2rem;width:4.2rem}}@media(max-width:767.98px){.VCircleButton_mDgyh._large_5TPT\\+ .text_1gBnr svg{height:14px;width:14px}}.VCircleButton_mDgyh._large-24_PrXpp{height:4.8rem;width:4.8rem}.VCircleButton_mDgyh._large-24_PrXpp .label_sb2e0 svg{height:2.4rem;width:2.4rem}.VCircleButton_mDgyh._primary_48KqB{background-color:#161119}.VCircleButton_mDgyh._primary_48KqB .label_sb2e0{color:#fff}.VCircleButton_mDgyh._primary_48KqB._active_vWfgV .label_sb2e0{color:#e7e6e7}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.VCircleButton_mDgyh._primary_48KqB:hover{background-color:#3a363d}}.VCircleButton_mDgyh._primary-900_cFCBy{background-color:#804dff}.VCircleButton_mDgyh._primary-900_cFCBy .label_sb2e0{color:#fff}.VCircleButton_mDgyh .label_sb2e0{align-items:center;display:inline-flex;font-size:1.2rem;justify-content:center;letter-spacing:-.005em;line-height:1.2;text-transform:uppercase;transition:color .3s ease;white-space:nowrap;width:100%}.VCircleButton_mDgyh .label_sb2e0 svg{height:1em;width:1em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"VCircleButton": "VCircleButton_mDgyh",
	"_disabled": "_disabled_XXltS",
	"_small": "_small_bmQRF",
	"_medium": "_medium_qgK9g",
	"text": "text_1gBnr",
	"_large": "_large_5TPT+",
	"_large-24": "_large-24_PrXpp",
	"label": "label_sb2e0",
	"_primary": "_primary_48KqB",
	"_active": "_active_vWfgV",
	"_primary-900": "_primary-900_cFCBy"
};
module.exports = ___CSS_LOADER_EXPORT___;
