
// components
import VCircleButton from '~/components/ui/button/VCircleButton.vue';
import VImage from '@@/common/components/ui/images/VImage.vue';

export default {
    name: 'FormSocial',

    components: {
        VImage,
        VCircleButton,
    },

    props: {
        links: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        title() {
            return 'Подписывайтесь на социальные <br>сети клуба «Движение»';
        },
    },
};
