// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormSocial_eiAy2{display:flex;flex-direction:column}.title_W1qjw{font-family:\"Bebas-Neue\",sans-serif;font-size:2.8rem;font-weight:700;letter-spacing:-.056rem;line-height:1;margin-bottom:1.8rem}@media(max-width:1279.98px){.title_W1qjw{font-size:1.8rem;margin-bottom:2rem}.title_W1qjw br{display:none}}.btns_o31\\+Q{align-items:center;display:flex}.btn_RuMfV+.btn_RuMfV{margin-left:.4rem}.image_W2CCV{height:1.6rem;width:1.6rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"FormSocial": "FormSocial_eiAy2",
	"title": "title_W1qjw",
	"btns": "btns_o31+Q",
	"btn": "btn_RuMfV",
	"image": "image_W2CCV"
};
module.exports = ___CSS_LOADER_EXPORT___;
