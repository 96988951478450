
export default {
    name: 'VCircleButton',

    props: {
        /**
         * Определяет тег компонента
         */
        tag: {
            type: String,
            default: 'button',
            validator: v => [
                'n-link',
                'a',
                'span',
                'div',
                'button',
                'input',
            ].includes(v),
        },

        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'medium',
            validator: value => ['small', 'medium', 'large', 'large-24'].includes(value),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'primary',
            validator: value => ['primary', 'primary-900'].includes(value),
        },

        /**
         * Активное состояние кнопки
         */
        active: Boolean,

        /**
         * Это свойство отключает взаимодействие
         */
        disabled: Boolean,
    },

    computed: {
        classList() {
            return {
                [this.$style[`_${this.color}`]]: this.color,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style._active]: this.active,
                [this.$style._disabled]: this.disabled,
            };
        },
    },

    methods: {
        /**
         * Эмитит событие клика в родительский компонент
         * @param {Event} event mouse event
         * @public
         */
        onClick($event) {
            /**
             * Cобытие клика в родительский компонент
             * @event click
             * @param {Event} event mouse event
             */
            this.$emit('click', $event);
        },

        /**
         * Эмитит cобытие при наведении на элемент
         * @param {Event} event mouse event
         * @public
         */
        onMouseEnter($event) {
            /**
             * Cобытие при наведении на элемент
             * @event mouseenter
             * @param {Event} event mouse event
             */
            this.$emit('mouseenter', $event);
        },

        /**
         * Эмитит событие, когда наведение на элемент прекращено
         * @param {Event} event mouse event
         * @public
         */
        onMouseLeave($event) {
            /**
             * Cобытие, когда наведение на элемент прекращено
             * @event mouseleave
             * @param {Event} event mouse event
             */
            this.$emit('mouseleave', $event);
        },
    },
};
